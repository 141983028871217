import { Typography, TextField, Link, Dialog, DialogTitle, DialogActions, Button, DialogContent, CircularProgress } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { getHotItem } from '../../../../../../commons/storage/storage';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const months = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
];

function formatDate(date){
  const d = new Date(date + ' 00:00:00');
  return d.getDate() + ' de ' + months[d.getMonth()] + ' de ' + d.getFullYear();
}

function Plans(){
  return (
    <Fragment>
      <Typography 
        variant={'h6'} 
        gutterBottom
        style={{marginBottom: 20}}
      >
        Nossos planos
      </Typography>
      <Typography variant={'body1'}>
        Anual: 154.00 R$/mês<br />
        Semestral: 174.00 R$/mês<br />
        Mensal: <span style={{textDecoration: 'line-through', opacity: 0.5}}>194.00 R$/mês</span> 95.00 R$/mês na promoção<br />
      </Typography>
    </Fragment>
  );
}

function SubscriptionTab(props){

  const
    profile = getHotItem('profile'),
    { subscriptionStatus } = profile,
    [ isSubscribing, setIsSubscribing ] = useState(false),
    [ plan, setPlan ] = useState(null),
    [ paymentType, setPaymentType ] = useState(null),
    [ sending, setSending ] = useState(false),
    [ response, setResponse ] = useState(null),
    handlePlanChange = (e) => {
      setPlan(e.target.value);
    },
    handlePaymentChange = (e) => {
      setPaymentType(e.target.value);
    },
    handleSubscriptionRequest = () => {
      setSending(true);
      const
        supportData = {
          requestType: "32",
          summary: 'Solicitação de assinatura MOU', 
          description: `${profile.personalAccount.name} solicitou a criação de uma assinatura. Tipo: ${plan}, Forma de pagamento: ${paymentType}`,
        }
      props.actions.createSupportRequest(supportData, (responseData) => {
        console.log(responseData);
        setSending(false);
        if(responseData.issueKey){
          setResponse('success');
        } else {
          setResponse('error');
        }
      });
    };

  return (
    <div>

      {isSubscribing &&
        <Dialog open={true} fullWidth>
          <DialogTitle>Assine MOU</DialogTitle>
          {(sending && response === null) &&  
            <DialogContent dividers>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress />
                <Typography variant={'body2'} style={{marginLeft: 8}}>Enviando...</Typography>
              </div>
            </DialogContent>
          }
          {(!sending && response === null) && 
            <DialogContent dividers>
              <Typography variant={'body1'} gutterBottom>
                Solicite sua assinatura diretamente conosco preenchendo o formulário abaixo. 
                Entraremos imediatamente em contato com você pelo e-mail de sua conta MOU enviando todos os dados necessários.<br /><br /></Typography>
              <FormControl component="fieldset">
                <FormLabel component="legend">Escolha seu tipo de plano:</FormLabel>
                <RadioGroup aria-label="plan" name="plan1" value={plan} onChange={handlePlanChange}>
                  <FormControlLabel value="anual" control={<Radio />} label="Anual: 154.00 R$/mês" />
                  <FormControlLabel value="semestral" control={<Radio />} label="Semestral: 174.00 R$/mês" />
                  <FormControlLabel 
                    value="mensal" 
                    control={<Radio />} 
                    label={<span>Mensal: <span style={{textDecoration: 'line-through', opacity: 0.5}}>194.00 R$/mês</span> 95.00 R$/mês na promoção</span>} 
                  />
                </RadioGroup>
              </FormControl>
              <br /><br />
              <FormControl component="fieldset">
                <FormLabel component="legend">Escolha a forma de pagamento:</FormLabel>
                <RadioGroup aria-label="plan" name="plan1" value={paymentType} onChange={handlePaymentChange}>
                  <FormControlLabel value="pix" control={<Radio />}           label="Pix" />
                  <FormControlLabel value="boleto" control={<Radio />}        label="Boleto" />
                  <FormControlLabel value="transferência" control={<Radio />} label="Transferência" />
                </RadioGroup>
              </FormControl>
            </DialogContent>
          }
          {response !== null &&
            <DialogContent dividers>
              {
                response === 'success'
                  ? <Typography variant={'body1'}>Solicitação enviada com successo!<br />Em breve, entraremos em contato.</Typography>
                  : <Typography variant={'body1'}>Não foi possível enviar sua solicitação.<br />Por favor, tente novamente mais tarde.</Typography>
              }
            </DialogContent>
          }
          <DialogActions>
            <Button
              disabled={sending}
              onClick={() => setIsSubscribing(false)}
            >
              {response === null ? 'Cancelar' : 'Fechar'}
            </Button>
            {response === null && 
              <Button
                variant={'contained'}
                disableElevation
                color={'primary'}
                onClick={handleSubscriptionRequest}
                disabled={sending || response !== null}
              >
                Enviar
              </Button>
            }
          </DialogActions>
        </Dialog>
      }

      {/*
      <Typography 
        variant={'body1'} 
        gutterBottom
      >
        Aqui você pode verificar e alterar seu plano, dados de cobrança e vencimento de sua assinatura.
      </Typography>
      */}
      {subscriptionStatus 
        ? <Fragment>

            {subscriptionStatus.status === 'on-trial' &&
              <Fragment>
                <Typography variant={'body1'}>
                  Você está no período de teste gratuíto. (Degustação MOU).<br />
                  Ainda tem {subscriptionStatus.promo.trialDaysLeft} dia{subscriptionStatus.promo.trialDaysLeft === 1 ? '' : 's'}.<br /><br />
                  Você também está participando da nossa promoção:<br />
                  Primeiros 3 meses por 95.00 R$/mês (valor original: 194 R$/mês)<br /><br />
                  Para assinar, <Link style={{cursor: 'pointer'}} onClick={() => setIsSubscribing(true)}>Clique aqui</Link>.<br /><br />
                </Typography>
                <Plans />
              </Fragment>
            }

            {(subscriptionStatus.status === 'unpaid' || subscriptionStatus.status === 'invalid-payment-status') && 
              <Fragment>
                <Typography variant={'body1'}>
                  Você precisa ativar ou renovar sua assinatura.<br /><br />
                  Para fazer isso, <Link style={{cursor: 'pointer'}} onClick={() => setIsSubscribing(true)}>Clique aqui</Link>.<br /><br />
                  Você está participando da nossa promoção:<br />
                  Primeiros 3 meses por 95.00 R$/mês (valor original: 194 R$/mês)<br /><br />
                </Typography>
                <Plans />
              </Fragment>
            }

            {(subscriptionStatus.status === 'paid') && 
              <Fragment>
                <Typography variant={'body1'}>
                  {subscriptionStatus.nextPayment && 
                    <span>
                      Sua assinatura está ativa e precisa ser renovada até o dia {formatDate(subscriptionStatus.nextPayment)}.<br /><br />
                      Para renovar, <Link style={{cursor: 'pointer'}} onClick={() => setIsSubscribing(true)}>Clique aqui</Link>.<br /><br />
                    </span>
                  }

                  Você está participando da nossa promoção:<br />
                  Primeiros 3 meses por 95.00 R$/mês (valor original: 194 R$/mês)<br /><br />
                  
                </Typography>
                <Plans />
              </Fragment>
            }

          </Fragment>

        : <Fragment>
            <Typography 
              variant={'body1'}
            >
              Sua conta faz parte de um contrato exclusivo com a MOU ou ó to tipo "convidado" de outra conta, 
              portanto não possui informação de assinatura.
              <br />
              <br />
              Para dúvidas ou perguntas, entre em contato conosco através do email <Link href={'mailto:suporte@mou.digital'}>suporte@mou.digital</Link>
            </Typography>
          </Fragment>
      }
    </div>
  );
}

export default SubscriptionTab;