import { Paper } from '@material-ui/core';
import React from 'react';
import MouIcon from '../../../../components/mou-icons/MouIcon';
import svg from './background.svg';

function NotLoggedInFrame(props) {
  return (
    <div style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, backgroundImage: `url(${svg})`, zIndex: 1200}}>
      <div style={{position: 'absolute', top: '50%', left: '50%', marginLeft: -200, marginTop: -200, zIndex: 1021}}>
        <Paper elevation={16} style={{width: 400, padding: 30}}>
          <MouIcon colorScheme={'red'} width={340} height={80}/>
          {props.children}
        </Paper>
      </div>
    </div>
  );
}

export default NotLoggedInFrame;