import { Paper, Typography, Divider, Link, Button, Box } from '@material-ui/core';
import React from 'react';
import MouIcon from '../mou-icons/MouIcon';
import svg from './browserIndicator.svg';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));


function BrowserIndicator(props) {
    const classes = useStyles();
    let 
        currentBrowser = props.browser,
        mouArea = props.area,
        userArea;

    if(mouArea === 'model'){
        userArea = 'Modelagem'
    }else if(mouArea === 'project'){
        userArea = 'Interpretação'
    }else{
        userArea = null
    };

    return (
      <div style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, backgroundImage: `url(${svg})`, zIndex: 1200}}>
        <div style={{position: 'absolute', top: '50%', left: '50%', marginLeft: -200, marginTop: -200, zIndex: 1021}}>
          <Paper elevation={16} style={{width: 500, padding: 30}}>
            <Typography variant="h4" gutterBottom align={'center'}>OPSSS! <Icon >sentiment_dissatisfied</Icon></Typography>
            <Typography variant="h5" gutterBottom >Você está acessando a área de <span style={{fontWeight: 'bold'}}>{userArea} </span> com o navegador <span style={{fontWeight: 'bold'}}>{currentBrowser}</span></Typography>
            <Typography variant="h6" gutterBottom style={{marginTop: 30}}>Atualmente, esse navegador não é suportado para a área que está acessando.</Typography>
            <Typography variant="h6" gutterBottom style={{marginTop: 30}}>Para aproveitar ao máximo das facilidades da modelagem digital, utilize um navegador recomendado <span style={{fontWeight: 'bold'}}>(Chrome)</span> ou entre em contato conosco!</Typography>
            <Link href={'https://mou-digital.atlassian.net/servicedesk/customer/kb/view/233308178'} underline='hover' style={{marginTop: 40, marginLeft: 100, }}>
                <Button variant='contained' color='primary' className={classes.button} endIcon={<Icon>contact_support</Icon>}>
                    Equipe Suporte MOU
                </Button>
            </Link>
            <div style={{marginTop: 20, marginLeft: 40}}>
                <MouIcon colorScheme={'red'} width={340} height={80} />
            </div>
            {/* {props.children} */}
          </Paper>
        </div>
      </div>
    );
  };
  
  export default BrowserIndicator;